import { COUNTRIES } from "../constants/countries";

// Helper function to get country name in specific language
export const getCountryName = (
    countryCode: string,
    language: string,
    fallbackLanguage = "en",
): string => {
    const country = COUNTRIES[countryCode];
    if (!country) return countryCode;

    return country[language] || country[fallbackLanguage] || countryCode;
};

// Purely remove accents, non alphanumeric characters and replace spaces with hyphens.
export const getNormalizedName = (name: string) =>
    name
        .toLowerCase()
        // Remove accents
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        // Replace spaces with hyphens
        .replace(/\s+/g, "-")
        // Remove any non-alphanumeric characters (except hyphens)
        .replace(/[^a-z0-9-]/g, "");

// Create url slug but remove accents on letters.
export const createCountrySlug = (
    countryCode: string,
    language: string,
    fallbackLanguage = "en",
): string => {
    const name = getCountryName(countryCode, language, fallbackLanguage);
    return getNormalizedName(name);
};
