/* eslint-disable sonarjs/no-duplicate-string */
const countryMap: { [key: string]: string } = {
    AUS: "Australia",
    AUT: "Austria",
    BEL: "Belgium",
    CAN: "Canada",
    CHE: "Switzerland",
    DEU: "Germany",
    DNK: "Denmark",
    ESP: "Spain",
    FIN: "Finland",
    FRA: "France",
    GBR: "United Kingdom",
    GRC: "Greece",
    IRL: "Ireland",
    ISL: "Iceland",
    ITA: "Italy",
    JPN: "Japan",
    LUX: "Luxembourg",
    NLD: "Netherlands",
    NOR: "Norway",
    NZL: "New Zealand",
    PRT: "Portugal",
    SWE: "Sweden",
    USA: "United States",
};

export const OECD_COUNTRIES = [
    "Australia",
    "Austria",
    "Belgium",
    "Canada",
    "Chile",
    "Colombia",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Greece",
    "Hungary",
    "Iceland",
    "Ireland",
    "Israel",
    "Italy",
    "Japan",
    "Korea",
    "Latvia",
    "Lithuania",
    "Luxembourg",
    "Mexico",
    "Netherlands",
    "New Zealand",
    "Norway",
    "Poland",
    "Portugal",
    "Slovak Republic",
    "Slovenia",
    "Spain",
    "Sweden",
    "Switzerland",
    "Turkey",
    "United Kingdom",
    "United States",
];

export const COP_7_COUNTRIES = [
    "Canada",
    "France",
    "Germany",
    "Italy",
    "Japan",
    "United Kingdom",
    "United States",
];

export const EMERGING_COUNTRIES = [
    "Brazil",
    "Russia",
    "India",
    "China",
    "South Africa",
    "Mexico",
    "Indonesia",
    "Turkey",
    "Saudi Arabia",
    "Argentina",
];

export const EU_COUNTRIES = [
    "Austria",
    "Belgium",
    "Bulgaria",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Greece",
    "Hungary",
    "Ireland",
    "Italy",
    "Latvia",
    "Lithuania",
    "Luxembourg",
    "Malta",
    "Netherlands",
    "Poland",
    "Portugal",
    "Romania",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
];

export function countryKeyToName(key: string): string {
    return countryMap[key] || key;
}
